@import "../../helpers/styles/variables";

.nav {
  height: 100%;
  width: 100%;
  background-color: #111;
  padding: 16px;
  color: white;

  display: flex;
  align-items: center;
  gap: 16px;

  overflow-y: auto;

  .nav-list {
    background-color: #111;
    border-top: 1px solid white;

    display: flex;
    flex-direction: column;
    flex: 1;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    max-height: 0;
    transition: max-height 0.2s linear;
    overflow: hidden;

    &.nav-open {
      max-height: 90vh;
      z-index: 2;
      opacity: 1;
    }
  }
  .nav-logo {
    font-size: 30px;
    text-align: center;
    margin-bottom: 0;
  }

  .nav-menu {
    list-style: none;
    padding: 30px 0;
  }

  .nav-item {
    color: rgba($color: #ffffff, $alpha: 0.5);
    padding: 12px 20px;
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);

    & .active {
      color: #fff;
    }
    &:hover {
      color: #fff;
    }
  }

  .nav-bottom {
    margin-top: auto;
    padding: 12px 20px;
    button {
      background-color: transparent;
      border: none;
    }
  }

  .nav-mobile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 40px;
      height: 40px;
    }
  }

  @include tablet-up {
    padding: 24px 0;
    flex-direction: column;
    align-items: unset;

    .nav-mobile-icon {
      display: none;
    }

    .nav-logo {
      font-size: 40px;
    }

    .nav-list {
      position: unset;
      max-height: unset;
      padding: 0;
      border: none;
      opacity: 1;
    }
  }
}
