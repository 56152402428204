.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.form-label {
  color: #3f4246;
  font-weight: 600;
  margin-bottom: 4px;
}
.form-input,
input {
  width: 280px;
  padding: 8px 16px;
  border: 1px solid $border-color;
}
.form-date {
  width: 280px;
}

.form-btn {
  display: flex;
  align-items: center;
}
.form-error {
  color: #dc3545;
  margin-bottom: 8px;
  font-size: 14px;
}

.form {
  .denomination-input {
    position: relative;
    width: fit-content;

    .currency {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  //   vendor
  .react-datepicker__input-container input {
    margin: 0;
    padding: 8px 16px;
    border: 1px solid $border-color;
    width: 100%;
  }

  @media screen and (max-width: 440px) {
    .form-input,
    .form-date {
      width: 250px;
    }
  }
}
