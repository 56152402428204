@import "../../helpers/styles/variables";

.table {
  border-collapse: collapse;
  width: 100%;

  .table-col {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  th.table-col {
    background-color: #f3f6f9;
    font-weight: 600;
    font-size: 18px;
  }
}
.table-title {
  padding-bottom: 20px;
  font-size: 20px;
}
