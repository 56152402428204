$primary-color: #1a1919;
$second-color: #a36f48;
$hover-color: #f2f2f2;
$border-color: #d7dee3;
$main-bg-color: #edeff1;

@mixin tablet-up {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: 767px) {
    @content;
  }
}
