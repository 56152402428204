@import "../../helpers/styles/variables";

.login {
  width: 100vw;
  height: 100vh;
  background-color: $main-bg-color;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow-y: auto;

  .login-box {
    width: 500px;
    // height: 90vh;
    background-color: white;
    padding: 32px;
  }
  .login-heading {
    text-align: center;
    font-size: 30px;
  }
  .form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
