.result-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  align-items: center;
  justify-content: center;
}

.result-modal {
  background-color: white;
  border-radius: 6px;
}

.result-modal-heading {
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  font-size: 20px;
  font-weight: 600;
}

.result-modal-body {
  padding: 20px 16px;

  p {
    padding: 8px 0;
  }

  table {
    margin-top: 16px;
  }

  td {
    padding: 8px 20px 8px 0;
  }

  .note {
    text-align: center;
    margin-top: 16px;
  }

  .result {
    color: #dc3545;
  }
}

.result-modal-footer {
  border-top: 1px solid #e9ecef;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0 20px;
}

.result-modal .enter-code {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.result-modal .enter-code input {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .result-modal {
    min-width: 1024px;
  }
}