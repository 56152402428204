.end-shift {
  h3 {
    margin-bottom: 16px;
  }
  .submit-btn {
    margin: 20px 0;
  }

  .report-table {
    .form .form-input,
    input {
      width: 100% !important;
    }
    h3 {
      margin-bottom: 16px;
    }
  }

  .card-report {
    textarea {
      outline: none;
      padding: 16px;
      font-size: 16px;
    }
  }
}
