.modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: white;
  border-radius: 6px;
  height: fit-content;
  max-height: 85%;
  min-height: 40%;
  overflow: auto;
}

.modal-heading {
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  font-size: 20px;
  font-weight: 600;
}

.modal-body {
  padding: 20px 16px;

  p {
    padding: 8px 0;
  }

  table {
    margin-top: 16px;
  }

  td {
    padding: 8px 20px 8px 0;
  }

  .note {
    text-align: center;
    margin-top: 16px;
  }

  .result {
    color: #dc3545;
  }
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0 20px;
}

.modal .enter-code {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.modal .enter-code input {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal {
    min-width: 700px;
  }
}