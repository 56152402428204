@import "../../helpers/styles/variables";

.select {
  position: relative;
  cursor: pointer;

  .select-value {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 8px 16px;
    border: 1px solid $border-color;

    span {
      flex: 1;
    }
    .placeholder {
      font-style: italic;
      color: #333;
    }
  }
  .select-option-list {
    position: absolute;
    top: 120%;
    left: 0;
    right: 0;
    background-color: white;

    display: none;
    z-index: 1;
    border: 1px solid $border-color;
  }
  .select-option {
    padding: 12px;

    & + .select-option {
      border-top: 1px solid #f6f6f6;
    }

    &:hover {
      background-color: $hover-color;
      transition: all linear 0.2s;
    }
  }
}
