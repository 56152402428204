@import "./helpers/styles/variables";
@import "./helpers/styles/form";

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #1a1919;
}
body {
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}
button,
input {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  outline: none;
}
button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.app {
  width: 100vw;
  position: relative;
}

.nav-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 9;
  @include tablet-up {
    height: 100vh;
    width: 200px;
  }
}
.main {
  background-color: $main-bg-color;
  padding: 60px 0 0;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;

  @include tablet-up {
    padding: 0 0 0 200px;
  }
}

.content {
  padding: 20px;
  margin: 16px;
  background-color: white;

  @include tablet-up {
    margin: 32px;
    padding: 32px;
  }
}

.title {
  padding: 32px 32px 0;
  font-size: 24px;
  color: #000;
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-between;
  gap: 0 16px;
}

.btn {
  $color: $primary-color;
  background-color: $color;
  border: 2px solid $color;
  padding: 8px 12px;
  color: white;
  min-width: 120px;
  flex-shrink: 0;

  &:hover {
    background-color: white;
    color: $color;
  }
}

.separate {
  width: 100%;
  height: 1px;
  background-color: rgb(95, 100, 112);
  margin: 36px 0;
}
