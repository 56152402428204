.autocomplete-container {
    position: relative;
    display: inline-block;
    width: -webkit-fill-available;

    .autocomplete-input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .suggestion-list {
        border: 1px solid #ccc;
        border-top: none;
        max-height: 150px;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        z-index: 1000;
        background: white;

        .suggestion-item {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #ddd;
            }
        }
    }
}

.select-box {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gray"><polygon points="0,0 20,0 10,10"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px;
}

.select-box:focus {
    border-color: #007bff;
    outline: none;
}