.voucher {
  .row {
    justify-content: flex-start;
  }

  .voucher-heading {
    color: #676567;
    margin-bottom: 24px;
  }

  .voucher-status {
    margin-top: 8px;
  }

  .false {
    color: red
  }

  .true {
    color: green
  }
}